import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs'
import { FaFlagUsa } from 'react-icons/fa'

const SocialMediaIcons = () => {
  const links = [
    {
        id: 1,
        child: (
           <>
            Linkedin <FaLinkedin size={30}/>
           </> 
        ),
        href: 'https://www.linkedin.com/in/thiago-lanza-65012832/',
        style: 'rounded-tr-md'
    },
    {
        id: 2,
        child: (
           <>
            Github <FaGithub size={30}/>
           </> 
        ),
        href: 'https://github.com/thlanza',
    },
    {
        id: 3,
        child: (
           <>
            Email <HiOutlineMail size={30}/>
           </> 
        ),
        href: 'mailto:thlanza@gmail.com',
    },
    {
        id: 4,
        child: (
           <>
            Currículo <BsFillPersonLinesFill size={30}/>
           </> 
        ),
        href: '/resume.pdf',
        download: true
    },
    {
        id: 4,
        child: (
           <>
            English Resume<FaFlagUsa size={30}/>
           </> 
        ),
        href: '/resume-en.pdf',
        download: true
    },
    {
        id: 5,
        child: (
           <>
             Instagram <img src="../assets/instagram.png"  alt="instagram icon" />
           </> 
        ),
        href: 'https://www.instagram.com/thlanza/',
    },
    {
        id: 6,
        child: (
           <>
             Facebook <img src="../assets/facebook.png" alt="facebook icon" />
           </> 
        ),
        href: 'https://www.facebook.com/ThiagoLanza',
        style: 'rounded-br-md',
    },
  ]

  return (
    <div className='hidden md:flex flex-row bottom-[5%] lg:top-[35%] left-0 fixed'>
        <ul>

            {links.map(({id, child, href, style, download}) => (
                    <li key={id}
                        className={"flex justify-between items-center w-40 h-14 px-4 bg-gray-500 ml-[-100px] hover:rounded-md duration-300 hover:ml-[-10px]" + " " + style}>
                        <a 
                            href={href} 
                            className='flex justify-between items-center w-full text-white'
                            download={download}
                            target='_blank'
                        >
                            {child}
                        </a>
                    </li>
            ))}
           
        </ul>
    </div>
  )
}

export default SocialMediaIcons