import React from 'react'
import { useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import useMediaQuery from '../hooks/useMediaQuery';

const Link =({ page, selectedPage, setSelectedPage, alias }) => {
    const lowerCasePage = page.toLowerCase();
    return (
        <AnchorLink
            className={`${selectedPage === lowerCasePage ? "text-yellow" : ""}
            hover:text-yellow transition duration-500
            `}
            href={`#${lowerCasePage}`}
            onClick={() => setSelectedPage(lowerCasePage)}
        >
          {alias}
        </AnchorLink>
    )
}

const Navbar = ({ isTopOfPage, selectedPage, setSelectedPage }) => {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isAboveSmallScreens = useMediaQuery("(min-width: 768px)");
  const navbarBackground = isTopOfPage ? "": "bg-red";
  return (
    <nav className={`${navbarBackground} z-40 w-full fixed top-0 py-6`}>
        <div className='flex items-center justify-between mx-auto w-5/6'>
            <h4 className="font-playfair text-3xl font-bold">TL</h4>

            {/* DESKTOP NAV */}
            {isAboveSmallScreens ? (
                <div className='flex justify-between gap-16 font-opensans text-sm font-semibold'>
                    <Link 
                        page="home"
                        alias="Home"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                    <Link 
                        page="skills"
                        alias="Habilidades"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                    <Link 
                        page="projects"
                        alias="Projetos"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                    <Link 
                        page="certificates"
                        alias="Certificados"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                    <Link 
                        page="contact"
                        alias="Contato"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
             
                </div>
            ) : 
            (
            <button
               className='rounded-full bg-red p-2' 
               onClick={() => setIsMenuToggled(!isMenuToggled)}
            >
                <img src="../assets/menu-icon.svg" alt="menu-icon" />
            </button>)}

            {/* MOBILE MENU POPUP */}
            {!isAboveSmallScreens && isMenuToggled && (
                <div className='fixed right-0 bottom-0 h-full bg-blue w-[300px]'>
                    {/* CLOSE ICON */}
                    <div className='flex justify-end p-12'>
                        <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                            <img alt="close-icon" src="../assets/close-icon.svg" />
                        </button>
                    </div>

                    {/* MENU ITEMS */}
                    <div className='flex flex-col gap-10 ml-[33%] text-2xl text-deep-blue'>
                    <Link 
                        page="home"
                        alias="Home"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                    <Link 
                        page="skills"
                        alias="Habilidades"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                    <Link 
                        page="projects"
                        alias="Projetos"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                    <Link 
                        page="contact"
                        alias="Contato"
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                    </div>
                </div>
            )}
        </div>
    </nav>
  )
}

export default Navbar