import React from 'react'
import { motion } from 'framer-motion';
import LineGradient from '../components/LineGradient';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';

const container = {
    hidden: {},
    visible: {
        transition: { staggerChildren: 0.2 }    
    }
}



const Certificates = () => {

  const createCertificateArray = () => {
    let certArray = [];
    for (let i = 0; i < 13; i++) {
      let indice = i + 1;
      let string = `assets/certificate-${indice}.jpg`;
      certArray.push(string);
    }
    return certArray;
  }
  const [cert, setCert] = useState(createCertificateArray());
  const [actualPage, setActualPage] = useState(0);
  const PER_PAGE = 2;
  const offset = actualPage * PER_PAGE;

  const certData = cert
  .slice(offset, offset + PER_PAGE)
  .map((element, i) => (
    <img src={element} alt="certificado" key={i} className='border-4 border-black'/>
  ));

  const certCount = Math.ceil(cert.length / PER_PAGE);

  function handlePageClick({ selected: selectedPage }) {
    setActualPage(selectedPage);
  }
  return (
    <section id="certificates" className='pt-48 pb-48'>
      <motion.div
        className='md:w-2/5 mx-auto text-center'
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 }
        }}
        >
        <div>
          <p className='font-playfair font-semibold text-4xl mb-5'>
            CER<span className='text-red'>TIFICADOS</span>
          </p>
          <div className='flex justify-center mt-5'>
            <LineGradient width='w-1/3' />
          </div>
          </div> 
        </motion.div>
        
        {/* PROJECTS */}
        <div className='flex flex-col justify-start'>
          <div className='flex justify-center items-center mt-5 mb-5'>
          <p className='font-opensans'>Número de certificados: {cert.length}. Use a paginação para ver todos.</p>
          </div>
          <motion.div
            className='sm:grid sm:grid-cols-2'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            variants={container}
            >
          <div className='relative flex'>
          {certData}
          <br />
            <div className='absolute left-0 -bottom-16'>
            <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próximo"}
            pageCount={certCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            />  
            </div>
          </div>
  
            </motion.div>
        </div>
    </section>
    
  )
}

export default Certificates