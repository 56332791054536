import LineGradient from "../components/LineGradient";
import React, { useState } from 'react'
import { motion } from "framer-motion";
import { useToasts } from "react-toast-notifications";
import axios from "axios";

const url = "https://lanza-mailer.fly.dev/enviarEmail";

const Contact = () => {
  const { addToast } = useToasts();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
        const res = await axios.post(url, formData);
        addToast('Mensagem enviada com sucesso! Obrigado pelo feedback!', { appearance: 'success' });
        console.log(res);
    } catch(e) {
        addToast('Erro no envio. Tente novamente mais tarde.', { appearance: 'error' });
        console.log(e);
    }
  };

  return (
    <section 
        id="contact"
        className="py-48"
        >
<motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 }
        }}
        >
          <div>
            <p className='font-playfair font-semibold text-4xl'>
                <span className="text-yellow">ME CONTATE</span> PARA COMEÇARMOS
            </p>
            <div className="flex md:justify-end my-5">
                <LineGradient width='mx-auto w-2/4' />
            </div>
        
          </div>
        </motion.div>

        {/* FORM & IMAGE */}
        <div className="md:flex md:justify-between gap-16 mt-5">
            <motion.div
              className="basis-1/2 flex justify-center"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.5 }}
              variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 }
              }} 
            >
            <img src="../assets/contact-image.jpeg" alt="contact" />
            </motion.div>

            <motion.div
             className="basis-1/2 mt-10 md:mt-0"
             initial="hidden"
             whileInView="visible"
             viewport={{ once: true, amount: 0.5 }}
             transition={{ delay: 0.2, duration: 0.5 }}
             variants={{
                 hidden: { opacity: 0, y: 50 },
                 visible: { opacity: 1, y: 0 }
             }}
            >
             <form
                onSubmit={onSubmit}
                action=""
             >
              <input 
                className="w-full bg-blue font-semibold placeholder-opaque-black p-3"
                type="text"
                name="name"
                placeholder="Seu nome"
                onChange={(e) => setFormData({...formData, name: e.target.value})}
              />
              
              <input 
                className="w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5"
                type="text"
                placeholder="Seu email"
                name="email" 
                onChange={(e) => setFormData({...formData, email: e.target.value})}
              />
              
                
                <textarea
              className="w-full bg-blue font-semibold placeholder-opaque-black p-3 mt-5"
              name="message"
              onChange={(e) => setFormData({...formData, message: e.target.value})}
              placeholder="Coloque aqui sua mensagem."
              rows="4"
              cols="50"
            />
            <button
              className="p-5 bg-yellow font-semibold text-deep-blue mt-5 hover:bg-red hover:text-white transition duration-500"
              type="submit"
            >
              ME MANDE UMA MENSAGEM
            </button>
          </form>
        </motion.div>
      </div>
    </section>
  )
}

export default Contact

