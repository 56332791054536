import { motion } from 'framer-motion'
import React from 'react'
import LineGradient from '../components/LineGradient';
import useMediaQuery from '../hooks/useMediaQuery'

const MySkills = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="skills" className='pt-10 pb-24'>
      {/* HEADER AND IMAGE SECTION */}
      <div className='md:flex md:justify-between md:gap-16 mt-32'>
        <motion.div
        className='md:w-1/3'
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 }
        }}
        >
          <p className='font-playfair font-semibold text-4xl mb-5'>
            MINHAS <span className='text-red'>HABILIDADES</span>
          </p>
          <LineGradient width='w-1/3' />
          <p className='mt-10 mb-7'>
            Comecei a trabalhar com programação em 2018, na Diretoria de Desenvolvimento Tecnológico, na SEPLAG.
            Lá desenvolvi projetos em React, Nodejs, e prestei também suporte ao usuário. Ajudei também a desenvolver a integração do sistema RH Responde
            com o sistema de mensageria Kafka. Em 2022, passei a trabalhar na Segov, onde comecei a desenvolver projetos em Python e Qlik Sense,
            além de também construir aplicações em React e Nodejs.
          </p>
        </motion.div>

        <div className='mt-16 md:mt-0'>
          {isAboveMediumScreens ? (
             <div className='relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
            before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]'>
                 <img 
                     src="assets/skills-image.png" 
                     alt="skills" 
                     className='z-10'
                 />
             </div>
          ): (
            <img 
                     src="assets/skills-image.png" 
                     alt="skills" 
                     className='z-10'
                 />
          )}
        </div>
      </div>

      {/* SKILLS */}
      <div className='md:flex md:justify-between mt-16 gap-32'>
            {/* FRONTEND */}
            <motion.div
              className='md:w-1/3 mt-10'
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.5 }}
              variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 }
              }}
            >
              <div className='relative h-32'>
                <div className='z-10'>
                  <p className='font-playfair font-semibold text-5xl'>01</p>
                  <p className='font-playfair font-semibold text-3xl mt-3'>Frontend</p>
                </div>
                <div className='w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]'/>
              </div>
              <p className='mt-5'>
                Habilidades em React, Redux, HTML e CSS.
              </p>
            </motion.div>

            {/* BACKEND */}
            <motion.div
              className='md:w-1/3 mt-10'
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 }
              }}
            >
              <div className='relative h-32'>
                <div className='z-10'>
                  <p className='font-playfair font-semibold text-5xl'>02</p>
                  <p className='font-playfair font-semibold text-3xl mt-3'>Backend</p>
                </div>
                <div className='w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]'/>
              </div>
              <p className='mt-5'>
                Habilidades em Nodejs, Python, Express e Flask.
              </p>
            </motion.div>

              {/* ANÁLISE DE DADOS */}
              <motion.div
              className='md:w-1/3 mt-10'
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 }
              }}
            >
              <div className='relative h-32'>
                <div className='z-10'>
                  <p className='font-playfair font-semibold text-5xl'>03</p>
                  <p className='font-playfair font-semibold text-3xl mt-3'>Análise de Dados</p>
                </div>
                <div className='w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]'/>
              </div>
              <p className='mt-5'>
                Habilidades em Python para análise de dados, Pandas e Qlik Sense.
              </p>
            </motion.div>
      </div>
    </section>
  )
}

export default MySkills