import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs'
import LineGradient from '../components/LineGradient';

const Social = () => {
    const links = [
        {
            id: 1,
            child: (
               <>
                Linkedin <FaLinkedin size={30}/>
               </> 
            ),
            href: 'https://www.linkedin.com/in/thiago-lanza-65012832/',
            style: 'rounded-tr-md border-b border-b-white',
        },
        {
            id: 2,
            child: (
               <>
                Github <FaGithub size={30}/>
               </> 
            ),
            style: 'border-b border-b-white',
            href: 'https://github.com/thlanza',
        },
        {
            id: 3,
            child: (
               <>
                Email <HiOutlineMail size={30}/>
               </> 
            ),
            style: 'border-b border-b-white',
            href: 'mailto:thlanza@gmail.com',
        },
        {
            id: 4,
            child: (
               <>
                Currículo <BsFillPersonLinesFill size={30}/>
               </> 
            ),
            href: '/resume.pdf',
            style: 'border-b border-b-white',
            download: true
        },
        {
            id: 5,
            child: (
               <>
                Currículo em Inglês (Resume in English)<BsFillPersonLinesFill size={30}/>
               </> 
            ),
            href: '/resume-en.pdf',
            style: 'border-b border-b-white',
            download: true
        },
        {
            id: 6,
            child: (
               <>
                 Instagram <img src="../assets/instagram.png"  alt="instagram icon" />
               </> 
            ),
            style: 'border-b border-b-white',
            href: 'https://www.instagram.com/thlanza/',
        },
        {
            id: 7,
            child: (
               <>
                 Facebook <img src="../assets/facebook.png" alt="facebook icon" />
               </> 
            ),
            href: 'https://www.facebook.com/ThiagoLanza',
            style: 'rounded-br-md',
        },
      ]
  return (
    <div name='Social' className='w-full 
    font-playfair font-semibold text-4xl mb-5 h-screen md:hidden'>

        <div className='max-w-screen-lg p-4 mx-auto flex flex-col
        w-full h-full font-'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline'>MINHAS <span className='text-yellow'>REDES SOCIAIS</span></p>
                <div className='flex justify-center mt-5'>
                <LineGradient width='w-1/3' />
            </div>
            </div>
      
            
            <div className='grid sm:grid-cols-2 gap-8 px-12 sm:px-0 font-opensans'>
        
            <ul>

                {links.map(({id, child, href, style, download}) => (
                        <li key={id}
                            className={"flex group hover:opacity-80 justify-between items-center w-full h-14 px-4 bg-gray-500 hover:rounded-md duration-300" + " " + style}>
                            <a 
                                href={href} 
                                className='flex justify-between items-center w-full text-white group-hover:font-bold'
                                download={download}
                                target='_blank'
                            >
                                {child}
                            </a>
                        </li>
                ))}

                </ul>
            </div>
        </div>
    </div>
  )
}

export default Social