import React from 'react'
import { motion } from 'framer-motion';
import LineGradient from '../components/LineGradient';

const container = {
    hidden: {},
    visible: {
        transition: { staggerChildren: 0.2 }    
    }
}

const projectVariant = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 }
}

const Project = ({ title, url }) => {
    const overlayStyles = `absolute w-[400px] h-[200px] opacity-0 hover:opacity-90 transition
    duration-500 bg-grey z-30 flex-col justify-center items-center text-center p-16 
    text-deep-blue object-cover object-top`
    const projectTitle = title.split(" ").join("-").toLowerCase();
    return (
        <motion.div variants={projectVariant} className="relative">
            <a 
            href={url} 
            className={overlayStyles}
            target="_blank"
            rel="noreferrer"
            >
                <p className='text-2xl font-playfair'>{title}</p>
            </a>
             <img className="z-40 w-[400px] h-[200px]" src={`../assets/portfolio/${projectTitle}.png`} alt={projectTitle} />
        </motion.div>
    )
}

const Projects = () => {
    
  return (
    <section id="projects" className='pt-48 pb-48'>
      <motion.div
        className='md:w-2/5 mx-auto text-center'
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
            hidden: { opacity: 0, y: -50 },
            visible: { opacity: 1, y: 0 }
        }}
        >
        <div>
          <p className='font-playfair font-semibold text-4xl mb-5'>
            PRO<span className='text-red'>JETOS</span>
          </p>
          <div className='flex justify-center mt-5'>
            <LineGradient width='w-1/3' />
          </div>
          </div> 
          <p className='mt-10 mb-10'>
           A seguir, meus dois últimos projetos, todos eles fullstack.
          </p>
        </motion.div>
        
        {/* PROJECTS */}
        <div className='flex justify-center'>
          <motion.div
            className='sm:grid sm:grid-cols-2'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            variants={container}
            >
          {/* ROW 1 */}
           <div
           className='flex justify-center text-center items-center p-10 bg-red
           max-w-[400px] max-h-[210px] text-2xl font-playfair font-semibold'
           >
            BELAS INTERFACES
           </div>
           <Project title="Front Paletta" url="https://front-paletta-thlanza.vercel.app/"/>
           <Project title="Vetados" url="https://vetados.fly.dev/" />
           <Project title="Data Jus" url="https://data-jus.netlify.app/" />
            </motion.div>
        </div>
    </section>
    
  )
}

export default Projects